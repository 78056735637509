import '../_snowpack/pkg/alpinejs.js'
import sal from '../_snowpack/pkg/saljs.js'
import '../_snowpack/pkg/saljs/dist/sal.css'
// animations

// const images = document.querySelectorAll('.animate-me')

// console.log(images)

// const observer = new IntersectionObserver((entries) => {
//   entries.forEach((entry) => {
//     if (entry.intersectionRatio > 0) {
//       entry.target.classList.add('fancy')
//     } else {
//       entry.target.classList.remove('fancy')
//     }
//   })
// })

// images.forEach((image) => {
//   observer.observe(image)
// })

var navBar = document.getElementById('site-nav')
var top = document.getElementById('top')

window.addEventListener('scroll', function () {
  if (window.scrollY >= 400) {
    navBar.classList.add('nav-sticky')
    top.classList.add('pt-scroll')
    top.setAttribute('style', 'opacity: 1')
  } else {
    navBar.classList.remove('nav-sticky')
    top.classList.remove('pt-scroll')
    top.setAttribute('style', 'opacity: .9')
  }
})

const menuBtn = document.getElementById('menuBtn')
let navContext = document.getElementById('context-menu')
let navPage = document.getElementById('page-menu')
let btn = document.getElementById('menuBtn')

menuBtn.addEventListener('click', navToggle)

function navToggle() {
  btn.classList.toggle('open')
  navContext.classList.toggle('flex')
  navContext.classList.toggle('hidden')
  navPage.classList.toggle('flex')
  navPage.classList.toggle('hidden')
}

navContext.addEventListener('click', function () {
  this.classList.add('hidden')
  navPage.classList.add('hidden')
  btn.classList.remove('open')
})

// Scroll to top button
// https://css-tricks.com/how-to-make-an-unobtrusive-scroll-to-top-button/

var scrollToTopBtn = document.getElementById('scrollToTopBtn')
var rootElement = document.documentElement

function handleScroll() {
  // Do something on scroll
  var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
  if (rootElement.scrollTop / scrollTotal > 0.8) {
    // Show button
    scrollToTopBtn.classList.add('showBtn')
  } else {
    // Hide button
    scrollToTopBtn.classList.remove('showBtn')
  }
}

// Scroll to top button

function scrollToTop() {
  // Scroll to top logic
  rootElement.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
scrollToTopBtn.addEventListener('click', scrollToTop)
document.addEventListener('scroll', handleScroll)

sal()
